<template>
  <div>
    <v-row
      justify="start"
      dense
      class="ml-2"
    >
      <v-col
        cols="12"
        md="2"
      >
        <v-text-field
          v-model="filterText"
          label="Pesquisar"
          placeholder="Gravação"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-btn
          @click="searchPrint"
          class="primary"
          icon
          dark
        >
          <v-icon small>fas fa-search</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-subheader>Tipos de gravação</v-subheader>
        <v-card
          max-height="250"
          flat
          class="overflow-auto"
        >
          <v-list two-line>
            <v-list-item-group
              v-model="selectedPrintIds"
              multiple
            >
              <v-list-item
                v-for="item in printTypes"
                :key="item.uniqueId"
                :value="item.uniqueId"
                active-class="primary--text text--accent-4"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                      color="primary"
                    />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle class="caption grey--text">
                      {{ item.description }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    printTypes: [],
    filterText: null,
  }),
  computed: {
    selectedPrintIds: {
      get() {
        if (
          this.product.product_print_types &&
          this.product.product_print_types.length > 0 &&
          typeof this.product.product_print_types[0] === 'object'
        ) {
          return this.product.product_print_types.map((item) => item.uniqueId)
        }
        return this.product.product_print_types || []
      },
      set(newIds) {
        this.product.product_print_types = newIds
          .map((id) => this.printTypes.find((item) => item.uniqueId === id))
          .filter((item) => item) // remove itens não encontrados
      },
    },
  },
  methods: {
    getPrint() {
      this.$api
        .get('product_prints')
        .then((res) => {
          this.printTypes = res.data.data.map((item, index) => ({
            ...item,
            uniqueId: item.id + '-' + index,
          }))
          console.log('this.printTypes: ', this.printTypes)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.printTypes.isLoading = false
        })
    },
    searchPrint() {
      this.printTypes = []
      this.$api
        .post('product_prints/filter', { name: this.filterText })
        .then((res) => {
          this.printTypes = res.data.data.map((item, index) => ({
            ...item,
            uniqueId: item.id + '-' + index,
          }))
          console.log(res, this.printTypes)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.printTypes.isLoading = false
        })
    },

    removeItem(print) {
      let i = 0
      this.product.product_print_types.map((item) => {
        if (item.uniqueId == print.uniqueId) {
          this.product.product_print_types.splice(i, 1)
        }
        i++
      })
    },
    addPrint(print, active, toggle) {
      console.log(print, active, toggle)
      var count = 0
      if (active == true) {
        this.product.product_print_types.map((item) => {
          if (item.uniqueId == print.uniqueId) count++
        })
        if (count == 0) {
          this.product.product_print_types.push(print)
        }
      } else {
        if (active == false) {
          this.product.product_print_types.map((item) => {
            console.log(item)
            if (item.uniqueId == print.uniqueId) count++
          })
          if (count > 0) {
            this.removeItem(print)
          }
        }
      }
    },
  },
  mounted() {
    this.getPrint()
  },
}
</script>
